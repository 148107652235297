import * as React from "react";
import Layout from "../../components/Layout";

import Image from "../../ui-blocks/Image";
import Tabs from "../../ui-blocks/Tabs";
import DigitasFiftyFifty from "../../ui-blocks/digitas-fifty-fifty";
import PrevNextNavFooter from "../../ui-blocks/PrevNextNavFooter/PrevNextNavFooter";
import ImageScroller from "../../ui-blocks/ImageScroller";

// import logos_header_desktop from '../../images/logo/section-header/logos_header_desktop.jpg';
import logos_logos_01_desktop from "../../images/logo/logos_logos_01_desktop.svg";

import logos_logos_02_mobile_svg from "../../images/3. Logo Page/Logo_Main_4_Mobile.svg";
import logos_logos_02_desktop_svg from "../../images/3. Logo Page/Logo_Main_4_Desktop.svg";

import Toyota_Main_Logo_H_svg from "../../images/3. Logo Page/Toyota_Main_Logo_H.svg";
import Toyota_Main_Logo_V_svg from "../../images/3. Logo Page/Toyota_Main_Logo_V.svg";

import Logo_LGP_H_svg from "../../images/3. Logo Page/Logo_LGP_H.svg";
import Logo_LGP_Stacked_svg from "../../images/3. Logo Page/Logo_LGP_Stacked.svg";
import Logo_LGP_V_svg from "../../images/3. Logo Page/Logo_LGP_V.svg";

import Logo_Placement_Hilux_Bottom_Center_svg from "../../images/3. Logo Page/Configurations/Logo_Placement_Hilux_Bottom_Center.svg";
import Logo_Placement_LGP_Top_Right_svg from "../../images/3. Logo Page/Configurations/Logo_Placement_LGP_Top_Right.svg";
import Logo_Placement_Main_Bottom_Right_svg from "../../images/3. Logo Page/Configurations/Logo_Placement_Main_Bottom_Right.svg";
import Logo_Placement_Sub_Brand_Top_Center_svg from "../../images/3. Logo Page/Configurations/Logo_Placement_Sub-Brand_Top_Center.svg";

import Logo_Vehicles_H_svg from "../../images/3. Logo Page/Logo_Vehicles_H.svg";
import Logo_Vehicles_V_svg from "../../images/3. Logo Page/Logo_Vehicles_V.svg";

import empty_png from "../../images/logo/empty.png";

import Toyota_Signature_Class_Logo_H_svg from "../../images/3. Logo Page/Toyota_Signature_Class_Logo_H.svg";
import Toyota_Signature_Class_Logo_V_svg from "../../images/3. Logo Page/Toyota_Signature_Class_Logo_V.svg";

import Logo_Clear_Space_3x3_svg from "../../images/3. Logo Page/Logo_Clear_Space_3x3.svg";
import Logo_Margins_svg from "../../images/3. Logo Page/Logo_Margins.svg";

import Logo_Clear_Space_H_svg from "../../images/3. Logo Page/Logo_Clear_Space_H.svg";
import Logo_Clear_Space_V_svg from "../../images/3. Logo Page/Logo_Clear_Space_V.svg";

import Logo_Minimium_Size_Main_svg from "../../images/3. Logo Page/Logo_Minimium_Size_Main.svg";
import Logo_Minimium_Size_LGP_H_svg from "../../images/3. Logo Page/Logo_Minimium_Size_LGP_H.svg";
import Logo_Minimium_Size_LGP_V_svg from "../../images/3. Logo Page/Logo_Minimium_Size_LGP_V.svg";

import Logo_Minimium_Size_Hilux_svg from "../../images/3. Logo Page/Logo_Minimium_Size_Hilux.svg";
import Logo_Minimium_Size_Signature_Class_svg from "../../images/3. Logo Page/Logo_Minimium_Size_Signature_Class.svg";

import Clear_Space_Toyota_LGP_Logo_V_svg from "../../images/3. Logo Page/Clear_Space_Toyota_LGP_Logo_V.svg";
import Clear_Space_Toyota_LGP_Logo_H_svg from "../../images/3. Logo Page/Clear_Space_Toyota_LGP_Logo_H.svg";

import Clear_Space_Toyota_Signature_Class_Logo_V_svg from "../../images/3. Logo Page/Clear_Space_Toyota_Signature_Class_Logo_V.svg";
import Clear_Space_Toyota_Signature_Class_Logo_H_svg from "../../images/3. Logo Page/Clear_Space_Toyota_Signature_Class_Logo_H.svg";

import Logo_Placement_LGP_Bottom_Right_1_svg from "../../images/3. Logo Page/Logo_Placement_LGP_Bottom_Right_1.svg";
import Logo_Placement_LGP_Bottom_Right_Floating_svg from "../../images/3. Logo Page/Logo_Placement_LGP_Bottom_Right_Floating.svg";

import Logo_Placement_LGP_Top_Right_Anchored_svg from "../../images/3. Logo Page/Logo_Placement_LGP_Top_Right_Anchored.svg";
import Logo_Placement_LGP_Top_Right_2_svg from "../../images/3. Logo Page/Logo_Placement_LGP_Top_Right_2.svg";

import Logo_Placement_LGP_Bottom_Center_svg from "../../images/3. Logo Page/Logo_Placement_LGP_Bottom_Center.svg";
import Logo_Placement_LGP_Top_Center_svg from "../../images/3. Logo Page/Logo_Placement_LGP_Top_Center.svg";

import Logo_Placement_Signature_Class_Bottom_Right_Anchored_svg from "../../images/3. Logo Page/Logo_Placement_Signature_Class_Bottom_Right_Anchored.svg";
import Logo_Placement_Signature_Class_Bottom_Right_Floating_svg from "../../images/3. Logo Page/Logo_Placement_Signature_Class_Bottom_Right_Floating.svg";

import Logo_Placement_Signature_Class_Top_Right_Anchored_svg from "../../images/3. Logo Page/Logo_Placement_Signature_Class_Top_Right_Anchored.svg";
import Logo_Placement_Signature_Class_Top_Right_Floating_svg from "../../images/3. Logo Page/Logo_Placement_Signature_Class_Top_Right_Floating.svg";

import Logo_Placement_Signature_Class_Bottom_Center_svg from "../../images/3. Logo Page/Logo_Placement_Signature_Class_Bottom_Center.svg";
import Logo_Placement_Signature_Class_Top_Center_svg from "../../images/3. Logo Page/Logo_Placement_Signature_Class_Top_Center.svg";

import Toyota_Lets_Go_Places_Logo_Full_Colour_H_svg from "../../images/3. Logo Page/Toyota_Let's_Go_Places_Logo_Full_Colour_H.svg";
import Toyota_Lets_Go_Places_Logo_H_White_svg from "../../images/3. Logo Page/Toyota_Let's_Go_Places_Logo_H_White.svg";

import Toyota_Lets_Go_Places_Logo_Single_Colour_Red_Stacked_01_svg from "../../images/3. Logo Page/Toyota_Let's_Go_Places_Logo_Single_Colour_Red_Stacked-01.svg";
import Toyota_Lets_Go_Places_Logo_Single_Colour_Black_Stacked_01_svg from "../../images/3. Logo Page/Toyota_Let's_Go_Places_Logo_Single_Colour_Black_Stacked-01.svg";
import Logo_Colour_Variation_LGP_Single_Colour_White_On_Red_svg from "../../images/3. Logo Page/Logo_Colour_Variation_LGP_Single_Colour_White_On_Red.svg";

import Toyota_Logo_Incorrect_Usage_1_svg from "../../images/3. Logo Page/_Incorrect Usage/Toyota_Logo_Incorrect_Usage_1.svg";
import Toyota_Logo_Incorrect_Usage_2_svg from "../../images/3. Logo Page/_Incorrect Usage/Toyota_Logo_Incorrect_Usage_2.svg";

import Toyota_Logo_Incorrect_Usage_3_svg from "../../images/3. Logo Page/_Incorrect Usage/Toyota_Logo_Incorrect_Usage_3.svg";
import Toyota_Logo_Incorrect_Usage_4_svg from "../../images/3. Logo Page/_Incorrect Usage/Toyota_Logo_Incorrect_Usage_4.svg";

import Toyota_Logo_Incorrect_Usage_5_svg from "../../images/3. Logo Page/_Incorrect Usage/Toyota_Logo_Incorrect_Usage_5.svg";
import Toyota_Logo_Incorrect_Usage_6_svg from "../../images/3. Logo Page/_Incorrect Usage/Toyota_Logo_Incorrect_Usage_6.svg";

import Toyota_Logo_Incorrect_Usage_7_svg from "../../images/3. Logo Page/_Incorrect Usage/Toyota_Logo_Incorrect_Usage_7.svg";
import Toyota_Logo_Incorrect_Usage_8_svg from "../../images/3. Logo Page/_Incorrect Usage/Toyota_Logo_Incorrect_Usage_8.svg";

import Toyota_Logo_Incorrect_Usage_9_svg from "../../images/3. Logo Page/_Incorrect Usage/Toyota_Logo_Incorrect_Usage_9.svg";
import Toyota_Logo_Incorrect_Usage_10_svg from "../../images/3. Logo Page/_Incorrect Usage/Toyota_Logo_Incorrect_Usage_10.svg";
import Toyota_Logo_Incorrect_Usage_11_svg from "../../images/3. Logo Page/_Incorrect Usage/Toyota_Logo_Incorrect_Usage_11.svg";
import Toyota_Logo_Incorrect_Usage_12_svg from "../../images/3. Logo Page/_Incorrect Usage/Toyota_Logo_Incorrect_Usage_12.svg";

import Logo_Imagery_Header_Placeholder_jpeg from "../../images/3. Logo Page/Logo_Imagery_Header_Placeholder.jpeg";

export default function Logo() {
  return (
    <Layout>
      <div
        className="page-header image-header"
        id="overview"
        data-navindex="1"
        style={{ backgroundColor: "#e9e9e9", height: "650px" }}
      >
        <div
          className="content-flex"
          style={{ color: "#fff", top: "", textAlign: "left" }}
        >
          <h1
            className="copy"
            style={{ color: "#fff", top: "", textAlign: "left" }}
          >
            As a brand, Toyota stands for quality, reliability and durability.
            Our logos and typography have been designed to reflect that.
          </h1>
        </div>

        <div className="background" style={{ backgroundColor: "#e9e9e9" }}>
          <div style={{ height: "100%" }}>
            <img
              alt=""
              src={Logo_Imagery_Header_Placeholder_jpeg}
              alt="logos_header_desktop"
            />
          </div>
        </div>
      </div>

      <section className="component Section" id="primary-logos">
        <div className="section-header darker-background">
          <div className="container">
            <h2 className="ui header">Primary Logos</h2>
          </div>
        </div>

        <div className="blog-content darker-background">
          <div className="container onecolumn" id="">
            <h3 className="title">Staging platform</h3>
            <div className="copy">
              <p className="spaced-p">
                The 'staging platform' is how we refer to the Toyota emblem when
                shown in the red surround. The staging platform allows the
                emblem to be anchored, impactful and instantly recognisable as
                Toyota.
              </p>
              <p className="spaced-p">
                The staging platform should only be used by itself or locked up
                with Toyota, <em>Let's Go Places</em>, a Vehicle Brand or a
                Toyota sub-brand. There should never be more than one Staging
                Platform logo on each layout.
              </p>
              <p className="spaced-p">
                Please do not create your own logo using the staging platform.
                Only use the logos provided and only as outlined in the brand
                guidelines.
              </p>
            </div>
          </div>
        </div>

        {/* The logo is logos_01_desktop.svg below  */}
        <Image
          variant="full-width"
          caption={null}
          image={{ svg: logos_logos_01_desktop, style: { height: "400px" } }}
        />

        <div
          className="blog-content darker-background"
          style={{ paddingTop: "80px" }}
        >
          <div className="container onecolumn" id="">
            <h3 className="title" style={{ color: "#000" }}>
              Four types of logos
            </h3>
            <div className="copy spaced-p">
              The Toyota brand logos, the <em>Let's Go Places</em> logos, and
              our various vehicle and sub-brand logos. Each is comprised of
              three elements: typography, the Toyota emblem and the staging
              platform. These elements not only simplify and unify our brand,
              but also help increase recognition of our products.
            </div>
          </div>
        </div>

        <Image
          variant="full-width"
          caption={null}
          style={{ marginTop: "32px" }}
          image={{
            svg: logos_logos_02_desktop_svg,
          }}
        />
      </section>

      <section
        className="component Section darker-background spaced-section"
        id="toyota"
      >
        <div className="blog-content darker-background">
          <div className="container onecolumn">
            <h3 className="title" style={{ paddingTop: "8px" }}>
              Toyota brand logo
            </h3>
            <div className="copy spaced-p">
              The Toyota Brand logo is used in brand comms such as internal
              comms, collateral and sponsorships. It can be used where the words{" "}
              <em>Let's Go Places</em> or vehicle badge is used in the headline,
              or where multiple sub-brands are being promoted. However, it
              should never be used in conjunction with any other staging
              platform logo.
            </div>

            <div className="copy">
              There are two configurations of this logo. Please do not adjust
              the size and placement of ‘Toyota’ in relation to the staging
              platform.
            </div>
          </div>
        </div>

        <Tabs
          section="logo"
          backgroundColor="white"
          items={[
            {
              content: {
                style: "image",
                image: {
                  svg: Toyota_Main_Logo_H_svg,
                  style: {
                    height: "200px",
                    marginTop: "80px",
                    marginBottom: "80px",
                  },
                },
              },
              label: "Horizontal",
            },
            {
              content: {
                style: "image",
                image: {
                  svg: Toyota_Main_Logo_V_svg,
                  style: {
                    height: "300px",
                    marginTop: "80px",
                    marginBottom: "80px",
                  },
                },
              },
              label: "Vertical",
            },
          ]}
        />
      </section>

      <section className="component Section" id="lets-go-places">
        {/* <div className='section-header darker-background'>
            <div className='container'>
                <h2 className='ui header'>Let's go places</h2>
            </div>
        </div> */}

        <div className="blog-content darker-background">
          <div className="container onecolumn">
            <h3 className="title" style={{ color: "#000" }}>
              <em>Let's Go Places</em>
            </h3>
            <div className="copy spaced-p">
              The <em>Let's Go Places</em> logo is used when promoting the
              Toyota brand essence, its products, and in the Messaging Platform.
            </div>
            <div className="copy spaced-p">
              There are 3 configurations of this logo, depending on the layout.
              It should never be used in conjunction with any other staging
              platform logo, nor should the size or placement of the{" "}
              <em>Let's Go Places</em> be modified.
            </div>
          </div>
        </div>

        <Tabs
          section="lets-go-places"
          style={{ paddingBottom: "0" }}
          items={[
            {
              content: {
                style: "image",
                image: {
                  // mobile: logos_lgp_horiz_01_mobile_svg,
                  // desktop: logos_lgp_horiz_01_desktop_svg,
                  svg: Logo_LGP_Stacked_svg,
                  style: { height: "570px" },
                },
              },
              label: "Horizontal Stacked",
            },
            {
              content: {
                style: "image",
                image: {
                  // mobile: logos_lgp_horiz_02_mobile_svg,
                  // desktop: logos_lgp_horiz_02_desktop_svg,
                  svg: Logo_LGP_H_svg,
                  style: { height: "570px" },
                },
              },
              label: "Horizontal",
            },
            {
              content: {
                style: "image",
                image: {
                  // mobile: logos_lgp_stacked_mobile_svg,
                  // desktop: logos_lgp_stacked_desktop_svg,
                  svg: Logo_LGP_V_svg,
                  style: { height: "570px" },
                },
              },
              label: "Vertical",
            },
          ]}
        />
      </section>

      <section className="component Section" id="vehicles">
        <div className="section-header darker-background"></div>

        <div className="blog-content darker-background">
          <div className="container onecolumn">
            <div className="copy spaced-p">
              <h3>Vehicle Brand logos for Brand Campaigns</h3>
              Vehicle Brand logos are treated differently depending on whether
              its a Vehicle Brand Campaign or being used within the Toyota
              Messaging Platform.
            </div>

            <div className="copy spaced-p">
              For Vehicle Brand Campaigns, the vehicle badge is locked up with
              the staging platform to create a 'Vehicle Brand Campaign logo'.
              These logos will be used on campaign elements including, but not
              limited to, print, digital, AV and OOH.
            </div>

            <div className="copy spaced-p">
              The logos should never be used in conjunction with any other
              staging platform logo. Please use the logos provided and do not
              adjust the size or placement of the vehicle logo in relation to
              the staging platform.
            </div>

            <div className="copy spaced-p">
              More Vehicle Brand Campaign logos not shown here will be developed
              on request. (Contact Toyota Marketing.)
            </div>
          </div>
        </div>

        {/* This tab should contain 4 images in a 2x2 grid */}
        <div className="blog-content">
          <div className="container onecolumn">
            <Tabs
              section="vehicles"
              style={{
                backgroundColor: "white",
                paddingTop: "32px",
                paddingBottom: 0,
              }}
              items={[
                {
                  content: {
                    style: "image",
                    image: {
                      // mobile: Toyota_Hilux_Logo_H_svg,
                      // desktop: Toyota_Hilux_Logo_H_svg,
                      svg: Logo_Vehicles_H_svg,
                      // style: {height: '400px'}
                    },
                  },
                  label: "Horizontal",
                },
                {
                  content: {
                    style: "image",
                    image: {
                      // mobile: Toyota_Hilux_Logo_V_svg,
                      // desktop: Toyota_Hilux_Logo_V_svg,
                      svg: Logo_Vehicles_V_svg,
                      // style: {height: '400px'}
                    },
                  },
                  label: "Vertical",
                },
              ]}
            />

            {/* <div className='copy'>
                    <h3>Yaris</h3>
                    Vehicle Brand logos are treated differently depending on whether it's a Vehicle Brand Campaign or being used 
                    within the Toyota Messaging Platform.
                </div> */}

            {/* <Tabs section='vehicles' backgroundColor='white' items={[
                    {
                        content: {
                            style: 'image', 
                            image: {
                                mobile: empty_png, 
                                desktop: empty_png,
                                style: {height: '400px'}
                            } 
                        },
                        label: 'Horizontal'
                    },
                    {
                        content: {
                            style: 'image', 
                            image: {
                                mobile: empty_png, 
                                desktop: empty_png,
                                style: {height: '400px'}
                            }
                        },
                        label: 'Vertical'
                    },
                ]}/> */}

            {/* 
                <div className='copy'>
                    <h3>Corolla</h3>
                    Vehicle Brand logos are treated differently depending on whether it's a Vehicle Brand Campaign or being used 
                    within the Toyota Messaging Platform.
                </div> */}

            {/* <Tabs section='logo' backgroundColor='white' items={[
                    {
                        content: {
                            style: 'image', 
                            image: {
                                mobile: empty_png, 
                                desktop: empty_png,
                                style: {height: '400px'}
                            }
                        },
                        label: 'Horizontal'
                    },
                    {
                        content: {
                            style: 'image', 
                            image: {
                                mobile: empty_png, 
                                desktop: empty_png,
                                style: {height: '400px'}
                            }
                        },
                        label: 'Vertical'
                    },
                ]}/> */}

            {/* <div className='copy'>
                    <h3>RAV4</h3>
                    Vehicle Brand logos are treated differently depending on whether it's a Vehicle Brand Campaign or being used 
                    within the Toyota Messaging Platform.
                </div>
                <br/> */}
            {/* <Tabs section='logo' backgroundColor='white' items={[
                    {
                        content: {
                            style: 'image', 
                            image: {
                                mobile: empty_png, 
                                desktop: empty_png,
                                style: {height: '400px'}
                            }
                        },
                        label: 'Horizontal'
                    },
                    {
                        content: {
                            style: 'image', 
                            image: {
                                mobile: empty_png, 
                                desktop: empty_png,
                                style: {height: '400px'}
                            }
                        },
                        label: 'Vertical'
                    },
                ]}/> */}
          </div>
        </div>
      </section>

      <section className="component Section darker-background" id="sub-brand">
        <div className="section-header darker-background">
          {/* <div className='container'>
                <h2 className='ui header'>Sub-Brand Logos</h2>
            </div> */}
        </div>

        <div className="blog-content darker-background">
          <div className="container onecolumn">
            <div className="copy spaced-p">
              <h3 style={{ paddingBottom: "10px", paddingTop: "8px" }}>
                Sub-Brand Logos
              </h3>
              There are XX Toyota sub-brands new New Zealand, and each of these
              have their own sub-brand logo. The logos use the staging platform
              to ensure that they are recognisable as being a Toyota brand and
              to create consistency, no matter the product, service or program.
            </div>

            <div className="copy spaced-p">
              The design and configuration of each sub brand logo has been
              individually crafted and carefully considered, so please do not
              type set it manually.
            </div>
          </div>
        </div>

        <Tabs
          section="sub-brand"
          style={{ backgroundColor: "white", paddingBottom: "80px" }}
          items={[
            {
              content: {
                style: "image",
                image: {
                  // mobile: Toyota_Signature_Class_Logo_H_svg,
                  // desktop: Toyota_Signature_Class_Logo_H_svg,
                  svg: Toyota_Signature_Class_Logo_H_svg,
                  style: { height: "200px", marginTop: "80px" },
                },
              },
              label: "Horizontal",
            },
            {
              content: {
                style: "image",
                image: {
                  // mobile: Toyota_Signature_Class_Logo_V_svg,
                  // desktop: Toyota_Signature_Class_Logo_V_svg,
                  svg: Toyota_Signature_Class_Logo_V_svg,
                  style: { height: "400px", marginTop: "80px" },
                },
              },
              label: "Vertical",
            },
          ]}
        />
      </section>

      <section
        className="component Section darker-background"
        id="configurations"
      >
        <div className="section-header">
          <div className="container">
            <h2 className="ui header">Configurations</h2>
          </div>
        </div>

        <div className="blog-content darker-background">
          <div className="container onecolumn">
            <div className="copy">
              <h3>Clear space</h3>
              Minimum clear space around the logo ensures visibility and
              clarity. The Toyota, <em>Let's Go Places</em>, vehicle or
              sub-brand logos should be kept clear of other logos and design
              elements. To measure clear space, the layout unit of measurement,
              "X" is used. "X" is derived from dividing the staging platform
              square into a 3x3 grid; each grid unit is equivalent to 1X. The
              minimum clear space required around the logo is 1X, both
              horizontally and vertically.
            </div>
          </div>
        </div>

        <Tabs
          section="sub-brand"
          backgroundColor="white"
          style={{ paddingBottom: 0 }}
          items={[
            {
              content: {
                style: "image",
                image: {
                  svg: Logo_Clear_Space_3x3_svg,
                  style: { height: "500px" },
                },
              },
              label: "X Value",
            },
            {
              content: {
                style: "image",
                image: {
                  // mobile: Toyota_Signature_Class_Logo_V_svg,
                  // desktop: Toyota_Signature_Class_Logo_V_svg,
                  svg: Logo_Margins_svg,
                  style: { height: "500px" },
                },
              },
              label: "Margins",
            },
          ]}
        />

        <Tabs
          section="sub-brand"
          backgroundColor="white"
          style={{ paddingBottom: 0 }}
          items={[
            {
              content: {
                style: "image",
                image: {
                  svg: Logo_Placement_Main_Bottom_Right_svg,
                  style: { height: "500px" },
                },
              },
              label: "Bottom Right",
            },
            {
              content: {
                style: "image",
                image: {
                  // mobile: Toyota_Signature_Class_Logo_V_svg,
                  // desktop: Toyota_Signature_Class_Logo_V_svg,
                  svg: Logo_Placement_LGP_Top_Right_svg,
                  style: { height: "500px" },
                },
              },
              label: "Top Right",
            },
            {
              content: {
                style: "image",
                image: {
                  // mobile: Toyota_Signature_Class_Logo_V_svg,
                  // desktop: Toyota_Signature_Class_Logo_V_svg,
                  svg: Logo_Placement_Hilux_Bottom_Center_svg,
                  style: { height: "500px" },
                },
              },
              label: "Bottom Centered",
            },
            {
              content: {
                style: "image",
                image: {
                  // mobile: Toyota_Signature_Class_Logo_V_svg,
                  // desktop: Toyota_Signature_Class_Logo_V_svg,
                  svg: Logo_Placement_Sub_Brand_Top_Center_svg,
                  style: { height: "500px" },
                },
              },
              label: "Top Centered",
            },
          ]}
        />

        <div className="blog-content darker-background spaced-section">
          <div className="container onecolumn">
            <div className="copy">
              <h3>Minimum size</h3>
              The minimum size of all logos is 9mm wide in print and 24 pixels
              wide in digital.
            </div>
          </div>
        </div>

        <Tabs
          section="minimum-size"
          backgroundColor="white"
          items={[
            {
              content: {
                style: "image",
                image: {
                  svg: Logo_Minimium_Size_LGP_H_svg,
                  style: { height: "400px" },
                },
              },
              label: "Horizontal",
            },
            {
              content: {
                style: "image",
                image: {
                  svg: Logo_Minimium_Size_LGP_V_svg,
                  style: { height: "400px" },
                },
              },
              label: "Vertical",
            },
          ]}
        />

        <Tabs
          section="logo-placement"
          backgroundColor="white"
          items={[
            {
              content: {
                style: "image",
                image: {
                  svg: Logo_Minimium_Size_LGP_H_svg,
                  style: { height: "400px" },
                },
                caption: {
                  copy: "<b>Bottom right - preferred</b><br/>We always aim to place the logo in the bottom right hand corner, anchored to the baseline of the artwork. Use the 1X for the margin widths as shown.",
                  align: "left",
                },
              },
              label: "Bottom Right",
            },
            {
              content: {
                style: "image",
                image: {
                  svg: Logo_Minimium_Size_LGP_V_svg,
                  style: { height: "400px" },
                },
              },
              label: "Top Right",
            },
          ]}
        />

        {/* <DigitasFiftyFifty
            first={
                {
                    image: Logo_Minimium_Size_Main_svg, 
                }
            } 
            second={
                {
                    image: Logo_Minimium_Size_LGP_H_svg,
                }
            }
        />

        <DigitasFiftyFifty
            first={
                {
                    image: Logo_Minimium_Size_Hilux_svg, 
                }
            } 
            second={
                {
                    image: Logo_Minimium_Size_Signature_Class_svg,
                }
            }
        /> */}

        <div className="blog-content darker-background spaced-section">
          <div className="container onecolumn">
            <div className="copy">
              <h3>Logo placement</h3>
              To create brand consistency across all forms of communication,
              there are four approved logo placements.
            </div>
          </div>
        </div>

        <ImageScroller
          style={{ backgroundColor: "white" }}
          images={[
            {
              image: {
                svg: Logo_Placement_LGP_Bottom_Right_1_svg,
                style: { height: "500px", width: "500px" },
              },
              caption: {
                copy: "<b>Bottom right - preferred</b><br/>We always aim to place the logo in the bottom right hand corner, anchored to the baseline of the artwork. Use the 1X for the margin widths as shown.",
                style: { marginTop: "-100px" },
              },
            },
            {
              image: {
                svg: Logo_Placement_LGP_Top_Right_Anchored_svg,
                style: { height: "500px", width: "500px" },
              },
              caption: {
                copy: "<b>Top right - preferred</b><br/>The logo can be anchored to the top of the artwork, using the 1X for the margin widths, as shown.",
                style: { marginTop: "-100px" },
              },
            },
            {
              image: {
                svg: Logo_Placement_LGP_Bottom_Right_Floating_svg,
                style: { height: "500px", width: "500px" },
              },
              caption: {
                copy: "When the logo can't sit anchored to the base of the artwork use the 1X for the margin widths as shown. ",
                style: { marginTop: "-100px" },
              },
            },
            {
              image: {
                svg: Logo_Placement_LGP_Top_Right_2_svg,
                style: { height: "500px", width: "500px" },
              },
              caption: {
                copy: "When the logo can't sit anchored to the top of the artwork use the 1X for the margin widths as shown.",
                style: { marginTop: "-100px" },
              },
            },
            {
              image: {
                svg: Logo_Placement_LGP_Bottom_Center_svg,
                style: { height: "500px", width: "500px" },
              },
              caption: {
                copy: "<b>Bottom centered</b><br/>When placing the logo in the centre of the artwork, make sure it sits no closer to the base of the artwork than the 1X as shown.",
                style: { marginTop: "-100px" },
              },
            },
            {
              image: {
                svg: Logo_Placement_LGP_Top_Center_svg,
                style: { height: "500px", width: "500px" },
              },
              caption: {
                copy: "<b>Top centered</b><br/>When placing the logo in the centre of the artwork, make sure it sits no closer to the top of the artwork than the 1X as shown.",
                style: { marginTop: "-100px" },
              },
            },
          ]}
        ></ImageScroller>

        {/* HILUX  */}
        <div className="blog-content darker-background spaced-section">
          <div className="container onecolumn">
            <div className="copy">
              <h3>Logo placement - Hilux</h3>
              To create brand consistency across all forms of communication,
              there are XXXX approved logo placements.
            </div>
          </div>
        </div>

        <ImageScroller
          style={{ backgroundColor: "white" }}
          images={[
            {
              image: {
                svg: Logo_Placement_LGP_Bottom_Right_1_svg,
                style: { height: "500px", width: "500px" },
              },
              caption: {
                copy: "<b>Bottom right - preferred</b><br/>We always aim to place the logo in the bottom right hand corner, anchored to the baseline of the artwork. Use the 1X for the margin widths as shown.",
                style: { marginTop: "-100px" },
              },
            },
            {
              image: {
                svg: Logo_Placement_LGP_Bottom_Right_Floating_svg,
                style: { height: "500px", width: "500px" },
              },
              caption: {
                copy: "When the logo can't sit anchored to the base of the artwork use the 1X for the margin widths as shown.",
                style: { marginTop: "-100px" },
              },
            },
            {
              image: {
                svg: Logo_Placement_LGP_Top_Right_Anchored_svg,
                style: { height: "500px", width: "500px" },
              },
              caption: {
                copy: "<b>Top right - preferred</b><br/>The logo can be anchored to the top of the artwork, using the 1X for the margin widths, as shown.",
                style: { marginTop: "-100px" },
              },
            },
            {
              image: {
                svg: Logo_Placement_LGP_Top_Right_2_svg,
                style: { height: "500px", width: "500px" },
              },
              caption: {
                copy: "When the logo can't sit anchored to the top of the artwork use the 1X for the margin widths as shown.",
                style: { marginTop: "-100px" },
              },
            },
            {
              image: {
                svg: Logo_Placement_LGP_Bottom_Center_svg,
                style: { height: "500px", width: "500px" },
              },
              caption: {
                copy: "<b>Bottom centered</b><br/>When placing the logo in the centre of the artwork, make sure it sits no closer to the base of the artwork than the 1X as shown.",
                style: { marginTop: "-100px" },
              },
            },
            {
              image: {
                svg: Logo_Placement_LGP_Top_Center_svg,
                style: { height: "500px", width: "500px" },
              },
              caption: {
                copy: "<b>Top centered</b><br/>When placing the logo in the centre of the artwork, make sure it sits no closer to the top of the artwork than the 1X as shown.",
                style: { marginTop: "-100px" },
              },
            },
          ]}
        ></ImageScroller>

        {/* Signature class  */}
        <div className="blog-content darker-background spaced-section">
          <div className="container onecolumn">
            <div className="copy">
              <h3>Logo placement - Signature Class</h3>
              To create brand consistency across all forms of communication,
              there are XXXX approved logo placements.
            </div>
          </div>
        </div>

        <ImageScroller
          style={{ backgroundColor: "white" }}
          images={[
            {
              image: {
                svg: Logo_Placement_Signature_Class_Bottom_Right_Anchored_svg,
                style: { height: "400px", width: "400px" },
              },
              caption: {
                copy: "<b>Bottom right - preferred</b><br/>We always aim to place the logo in the bottom right hand corner, anchored to the baseline of the artwork. Use the 1X for the margin widths as shown.",
                style: { marginTop: "-100px" },
              },
            },
            {
              image: {
                svg: Logo_Placement_Signature_Class_Bottom_Right_Floating_svg,
                style: { height: "400px", width: "400px" },
              },
              caption: {
                copy: "When the logo can't sit anchored to the base of the artwork use the 1X for the margin widths as shown.",
                style: { marginTop: "-100px" },
              },
            },
            {
              image: {
                svg: Logo_Placement_Signature_Class_Top_Right_Anchored_svg,
                style: { height: "400px", width: "400px" },
              },
              caption: {
                copy: "<b>Top right</b><br/>The logo can be anchored to the top of the artwork, using the 1X for the margin widths, as shown.",
                style: { marginTop: "-100px" },
              },
            },
            {
              image: {
                svg: Logo_Placement_Signature_Class_Top_Right_Floating_svg,
                style: { height: "400px", width: "400px" },
              },
              caption: {
                copy: "When the logo can't sit anchored to the top of the artwork use the 1X for the margin widths as shown.",
                style: { marginTop: "-100px" },
              },
            },
            {
              image: {
                svg: Logo_Placement_Signature_Class_Bottom_Center_svg,
                style: { height: "400px", width: "400px" },
              },
              caption: {
                copy: "<b>Bottom centered</b><br/>When placing the logo in the centre of the artwork, make sure it sits no closer to the base of the artwork than the 1X as shown.",
                style: { marginTop: "-100px" },
              },
            },
            {
              image: {
                svg: Logo_Placement_Signature_Class_Top_Center_svg,
                style: { height: "400px", width: "400px" },
              },
              caption: {
                copy: "<b>Top centered</b><br/>When placing the logo in the centre of the artwork, make sure it sits no closer to the top of the artwork than the 1X as shown.",
                style: { marginTop: "-100px" },
              },
            },
          ]}
        ></ImageScroller>
      </section>

      <section className="component Section" id="colour-variations">
        <div className="section-header darker-background">
          <div className="container">
            <h2 className="ui header">Colour Variations</h2>
          </div>
        </div>

        <div
          className="blog-content darker-background"
          style={{ paddingBottom: "80px" }}
        >
          <div className="container onecolumn" id="">
            <h3 className="title">Full-colour logos - preferred</h3>
            <div className="copy">
              There are two full-colour options available for each of the four
              approved types of logo. The full-colour primary logos should be
              used on a white or light background and the full-colour secondary
              logos should be used on a mid-value or dark background.
            </div>
          </div>
        </div>

        <Tabs
          section="colour-variations"
          backgroundColor="white"
          items={[
            {
              content: {
                style: "image",
                image: {
                  svg: Toyota_Lets_Go_Places_Logo_Full_Colour_H_svg,
                  style: { height: "200px" },
                },
              },
              label: "Full-Colour Primary",
            },
            {
              content: {
                style: "image",
                image: {
                  svg: Toyota_Lets_Go_Places_Logo_H_White_svg,
                  style: { height: "200px", backgroundColor: "black" },
                },
              },
              label: "Full-Colour Secondary",
            },
          ]}
        />

        <div className="blog-content darker-background spaced-section">
          <div className="container onecolumn" id="">
            <h3 className="title">Single-colour logos</h3>
            <div className="copy">
              <p className="spaced-p">
                When necessary, single-colour options are available. They are
                black or Toyota Red on a white background, or a reversed white
                logo on a black or Toyota Red background.
              </p>
              <p>
                Alternative background colours and textures may be used when a
                co-branding partner controls the background. In such an
                instance, ensure that legibility and impact are optimised.
              </p>
            </div>
          </div>
        </div>

        {/* TODO: see how to use a color-button tabs */}
        <Tabs
          section="colour-variations"
          backgroundColor="white"
          items={[
            {
              content: {
                style: "image",
                image: {
                  svg: Toyota_Lets_Go_Places_Logo_Single_Colour_Red_Stacked_01_svg,
                  style: { height: "400px" },
                },
              },
              label: "Red",
            },
            {
              content: {
                style: "image",
                image: {
                  svg: Toyota_Lets_Go_Places_Logo_Single_Colour_Black_Stacked_01_svg,
                  style: { height: "400px" },
                },
              },
              label: "Black",
            },
            {
              content: {
                style: "image",
                image: {
                  svg: Logo_Colour_Variation_LGP_Single_Colour_White_On_Red_svg,
                  style: { height: "400px" },
                },
              },
              label: "White",
            },
          ]}
        />
      </section>

      <section className="component Section" id="incorrect-use">
        <div className="section-header darker-background">
          <div className="container">
            <h2 className="ui header">Incorrect Use</h2>
          </div>
        </div>

        <div
          className="blog-content darker-background"
          style={{ paddingBottom: "80px" }}
        >
          <div className="container onecolumn" id="">
            <div className="copy">
              For both building brand awareness and protecting the trademark of
              our brand, it's important to retain consistent and correct use of
              all our logos. In general, do not alter any of the logos in any
              way. The following examples illustrate possible misuses and simple
              rules to follow. These examples apply across our entire suite of
              logos.
            </div>
          </div>
        </div>

        <ImageScroller
          style={{ backgroundColor: "white" }}
          images={[
            {
              image: {
                svg: Toyota_Logo_Incorrect_Usage_1_svg,
                style: { height: "400px", width: "400px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: Toyota_Logo_Incorrect_Usage_2_svg,
                style: { height: "400px", width: "400px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: Toyota_Logo_Incorrect_Usage_3_svg,
                style: { height: "400px", width: "400px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: Toyota_Logo_Incorrect_Usage_4_svg,
                style: { height: "400px", width: "400px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: Toyota_Logo_Incorrect_Usage_5_svg,
                style: { height: "400px", width: "400px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: Toyota_Logo_Incorrect_Usage_6_svg,
                style: { height: "400px", width: "400px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: Toyota_Logo_Incorrect_Usage_7_svg,
                style: { height: "400px", width: "400px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: Toyota_Logo_Incorrect_Usage_8_svg,
                style: { height: "400px", width: "400px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: Toyota_Logo_Incorrect_Usage_9_svg,
                style: { height: "400px", width: "400px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: Toyota_Logo_Incorrect_Usage_10_svg,
                style: { height: "400px", width: "400px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: Toyota_Logo_Incorrect_Usage_11_svg,
                style: { height: "400px", width: "400px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: Toyota_Logo_Incorrect_Usage_12_svg,
                style: { height: "400px", width: "400px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
          ]}
        ></ImageScroller>
      </section>
      <PrevNextNavFooter
        previousText="Previous"
        previousHeader="Introduction"
        previousUrl="/brandguidelines/introduction"
        nextText="Next"
        nextHeader="Tagline"
        nextUrl="/brandguidelines/tagline"
      />
    </Layout>
  );
}
